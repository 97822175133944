import ImagePreview from '@modules/ImagePreview';
import {
  notification,
  Popover,
  Select,
  Spin,
  Typography,
  Switch,
  Space,
} from 'antd';
import {
  FileOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  MailOutlined,
  AudioOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { getAttachmentType } from '@utils/utilities';
import { useEffect, useState } from 'react';
import {
  getAttachment,
  PenaltyAttachmentLabel,
  updatePenalty,
} from '@services/penalty';

const { Link } = Typography;

const JustificationAttachments = ({
  list,
  penalty,
}: {
  list: [{ url: string; file_type: string; exportable?: boolean }];
  penalty: any;
}) => {
  const [attachments, setAttachments] = useState<any>(null);
  const [isLoading, setLoading] = useState<any>(false);
  const [rawAttachments, setRawAttachments] = useState(list);

  useEffect(() => {
    const fetchAttachment = async (url: string) => {
      try {
        const attachmentData = await getAttachment(url);
        return attachmentData;
      } catch (error) {
        return null;
      }
    };

    const fetchAllAttachments = async () => {
      setLoading(true);
      try {
        const results = await Promise.all(
          list.map(async item => {
            const downloadUrl = await fetchAttachment(item.url);
            return {
              sharepoint_url: item.url,
              download_url: downloadUrl.url,
              edit_url: downloadUrl.edit_url,
              file_type: item.file_type,
              exportable: item.exportable || false,
            };
          }),
        );
        setAttachments(results as any);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (list && list.length > 0) {
      fetchAllAttachments();
    }
  }, [list]);

  const attachmentsOptions = [
    { value: 'FDC', label: PenaltyAttachmentLabel.FDC },
    {
      value: 'EMAIL_SENNDER',
      label: PenaltyAttachmentLabel.EMAIL_SENNDER,
    },
    {
      value: 'SHIPOWNER_COMMUNICATION',
      label: PenaltyAttachmentLabel.SHIPOWNER_COMMUNICATION,
    },
    {
      value: 'POLICE_REPORT',
      label: PenaltyAttachmentLabel.POLICE_REPORT,
    },
    {
      value: 'TRAFFIC_EVIDENCE',
      label: PenaltyAttachmentLabel.TRAFFIC_EVIDENCE,
    },
    {
      value: 'TRAFFIC_PHOTO',
      label: PenaltyAttachmentLabel.TRAFFIC_PHOTO,
    },
    {
      value: 'GPS',
      label: PenaltyAttachmentLabel.GPS,
    },
    {
      value: 'GPS_SPEED',
      label: PenaltyAttachmentLabel.GPS_SPEED,
    },
    {
      value: 'LANE_HISTORY',
      label: PenaltyAttachmentLabel.LANE_HISTORY,
    },
    {
      value: 'REPAIR_REPORT',
      label: PenaltyAttachmentLabel.REPAIR_REPORT,
    },
    {
      value: 'INVOICE',
      label: PenaltyAttachmentLabel.INVOICE,
    },
    {
      value: 'INCIDENT_PHOTO',
      label: PenaltyAttachmentLabel.INCIDENT_PHOTO,
    },
    {
      value: 'OTHER',
      label: PenaltyAttachmentLabel.OTHER,
    },
  ];

  if (!attachments || attachments.length === 0) {
    return null;
  }

  const handleAttachmentUpdate = async (
    type: string | boolean,
    url: string,
    field: 'file_type' | 'exportable',
  ) => {
    const payload = rawAttachments.map((item: any) => {
      if (item.url === url) {
        return {
          ...item,
          [field]: type,
        };
      }
      return item;
    });
    setRawAttachments(payload as any);
    try {
      setLoading(true);

      await updatePenalty(penalty._id, {
        justification: { ...penalty.justification, attachments_url: payload },
      });
      notification.success({ message: 'Attachment updated successfully' });
    } catch (error) {
      notification.error({
        message: 'Cannot update attachment',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div>
        {attachments &&
          attachments.map((attachment: any, index: number) => {
            const attachmentType = getAttachmentType(attachment.sharepoint_url);
            let fileIcon = <FileOutlined style={{ fontSize: '25px' }} />;

            switch (attachmentType) {
              case 'image':
                fileIcon = <FileImageOutlined style={{ fontSize: '25px' }} />;
                break;
              case 'pdf':
                fileIcon = <FilePdfOutlined style={{ fontSize: '25px' }} />;
                break;
              case 'doc':
                fileIcon = <FileExcelOutlined style={{ fontSize: '25px' }} />;
                break;
              case 'email':
                fileIcon = <MailOutlined style={{ fontSize: '25px' }} />;
                break;
              case 'audio':
                fileIcon = <AudioOutlined style={{ fontSize: '25px' }} />;
                break;
              case 'video':
                fileIcon = <VideoCameraOutlined style={{ fontSize: '25px' }} />;
                break;
              default:
                fileIcon = <FileOutlined style={{ fontSize: '25px' }} />;
                break;
            }
            return (
              <Popover
                placement={'top'}
                key={index}
                content={
                  <ImagePreview
                    attachmentType={attachmentType}
                    downloadUrl={attachment.download_url}
                    attachmentUrl={attachment.sharepoint_url}
                  />
                }
                title={
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <Select
                      defaultValue={attachment.file_type}
                      style={{ width: '100%' }}
                      options={attachmentsOptions}
                      onChange={(type: string) =>
                        handleAttachmentUpdate(
                          type,
                          attachment.sharepoint_url,
                          'file_type',
                        )
                      }
                    />
                    <Space>
                      <span>Exportable:</span>
                      <Switch
                        defaultChecked={attachment.exportable}
                        onChange={(checked: boolean) =>
                          handleAttachmentUpdate(
                            checked,
                            attachment.sharepoint_url,
                            'exportable',
                          )
                        }
                      />
                    </Space>
                  </Space>
                }
              >
                <Link
                  key={index}
                  href={attachment.edit_url || attachment.sharepoint_url}
                  target="_blank"
                >
                  {fileIcon}
                </Link>
              </Popover>
            );
          })}
      </div>
    </Spin>
  );
};

export default JustificationAttachments;
